<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/index_a.png" alt="" width="28">
        <h2>我的审批</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
    </div>
    <div class="contentBody">
      <div class="tableHead ">
        <!--        <el-select v-model="value" size="small" placeholder="请选择类型">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>-->
        <el-input @change="skipPage(1)" clearable v-model="search" placeholder="请输入实验编号"
                  suffix-icon="el-icon-search"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column
            prop="number"
            label="实验编号"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="实验类型">
          <template slot-scope="scope">
            <span :class="scope.row.isProd == 0 ? 'experiment' : 'product'">{{
                scope.row.isProd == 0 ? '实验课题' : '生产课题'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="实验名称"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="applyerName"
            label="申请人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            label="申请日期">
          <template slot-scope="scope">
            <span v-if="scope.row.applyDate">{{ scope.row.applyDate | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="审批状态" width="120">
          <template slot-scope="scope">
            <el-button size="small" class="suspended" v-if="scope.row.approveResult==1">待审批</el-button>
            <el-button size="small" class="finished" v-else-if="scope.row.approveResult==2">已通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.approveResult==3">已拒绝</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.approveResult==1"
                       @click="toDetail(scope.row.isProd,scope.row.subId,scope.row.approveResult,scope.row.id)">
              审批
            </el-button>
            <el-button type="text" v-else
                       @click="toDetail(scope.row.isProd,scope.row.subId,scope.row.approveResult,scope.row.id)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "approvalList",
  data() {
    return {
      search: "",
      options: [{
        value: '选项1',
        label: '生产课题'
      }, {
        value: '选项2',
        label: '实验课题'
      }],
      value: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    getData() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
        key: that.search
      }
      that.$get("subject/approval/byPage", params).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.records;
          this.page.current = res.data.current;
          this.page.total = res.data.total;
          this.page.size = res.data.size;
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    toDetail(isProd, subId, result, id) {
      this.$router.push({
        name: 'approvalDetail',
        query: {isProd: isProd, subId: subId, result: result, id: id}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/approval";
</style>
